import './rates-form.scss';

import { Field, useFormikContext } from 'formik';

import { MenuItem, styled, Switch } from '@mui/material';
import { NumberInput } from 'components/number-input/number-input';
import { OccupancyType } from 'graphql/gql-types';
import { ProxyType } from 'graphql/gql-types';
import { RateProxyOffsetInput } from 'components/rate-proxy-offset-input/rate-proxy-offset-input';
// import { PricingStrategy } from 'graphql/gql-types';
import { RatesFormValues } from './rates';
import { SeasonSelect } from '../season-select/season-select';
import { Select } from 'formik-mui';
import { unsavedDataChanged } from 'features/rules/redux/rules-slice';
import { useAppDispatch } from 'redux/hooks';
import { useCompetitorsController } from '../competitors/use-competitors-controller';
import { useEffect, useState } from 'react';
import { useUser } from 'features/users/context/userContext';
import styles from 'common/_variables.module.scss';

// const pricingStrategyOptions = [
//   { label: 'Neutral', value: PricingStrategy.neutral },
//   { label: 'Aggressive', value: PricingStrategy.aggressive },
//   { label: 'Conservative', value: PricingStrategy.conservative },
// ];

const GreenSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: styles.colorGray,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: styles.colorGray,
  },
  '.Mui-checked': {
    color: styles.colorGreen,
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: styles.colorGreen,
  },
}));

interface RatesFormProps {
  useProxy?: boolean;
}

const occupancyTypeOptions = [
  { label: 'Single', value: OccupancyType.single },
  { label: 'Double', value: OccupancyType.double },
];

const signOptions = ['+', '-'];

const proxyTypeOptions = [
  { label: '+', value: ProxyType.value },
  { label: '%', value: ProxyType.percent },
];

export const RatesForm = ({ useProxy = false }: RatesFormProps) => {
  const { isReadOnly } = useUser();
  const dispatch = useAppDispatch();
  const { values, dirty, handleChange } = useFormikContext<RatesFormValues>();
  const { propertyCompetitors } = useCompetitorsController();

  const proxyCompIdOptions = propertyCompetitors?.map((comp) => ({
    label: comp.comp_name,
    value: comp.comp_id,
  }));

  const [showProxy, setShowProxy] = useState(useProxy);

  useEffect(() => {
    dispatch(unsavedDataChanged(dirty));
    setShowProxy(!!Number(values.proxy_offset_amt));
  }, [dirty, dispatch, values]);

  return (
    <div className='rates-form'>
      <div className='row season-name'>
        <SeasonSelect />
      </div>
      {/* Hidden from display until fully implemented per B20V-2305: */}
      {/* <div className='row'>
        <h2 className='header'>Pricing Strategy</h2>
        <div className='common-dropdown-container strategy'>
          <Field name='pricingStrategy' component={Select}>
            {pricingStrategyOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        </div>
      </div> */}
      <div className='row'>
        <h2 className='header'>Rate Boundary</h2>
      </div>
      <div className='body'>
        <div className='field-box'>
          <div className='label'>Min</div>
          <NumberInput
            name='minRate'
            value={values.minRate}
            onChange={handleChange}
            isFloat
            disabled={isReadOnly}
          />
        </div>
        <div className='field-box'>
          <div className='label'>Max</div>
          <NumberInput
            name='maxRate'
            value={values.maxRate}
            onChange={handleChange}
            isFloat
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className='row'>
        <h2 className='header'>Derived Rate</h2>
      </div>
      <div className='body'>
        <div className='field-box'>
          <div className='label'>Occupancy</div>
          <Field
            className='common-dropdown-container'
            name='derivedRateType'
            component={Select}
            disabled={isReadOnly}
          >
            {occupancyTypeOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div className='field-box'>
          <div className='label'>Offset</div>
          <Field
            className='common-dropdown-container offset'
            name='derivedRateOffsetSign'
            component={Select}
            disabled={isReadOnly}
          >
            {signOptions.map((sign) => (
              <MenuItem key={sign} value={sign}>
                {sign}
              </MenuItem>
            ))}
          </Field>
          <NumberInput
            name='derivedRateOffset'
            value={values.derivedRateOffset}
            onChange={handleChange}
            isFloat
            disabled={isReadOnly}
            maxAmount={
              values.maxRate ? parseFloat(values.maxRate) * 0.2 : undefined
            }
          />
        </div>
      </div>
      <div className='row'>
        <h2 className='header'>
          <GreenSwitch
            checked={showProxy}
            onChange={(event) => {
              const show = event.target.checked;
              setShowProxy(show);
            }}
            size='small'
          />
          Rate Proxy
        </h2>
      </div>
      {showProxy && (
        <>
          <div className='body'>
            <div className='field-box'>
              <Field
                className='common-dropdown-container rate-proxy'
                name='proxy_comp_id'
                component={Select}
                disabled={isReadOnly}
              >
                <MenuItem key='market' value={0}>
                  Market Rate
                </MenuItem>
                {proxyCompIdOptions?.map(({ label, value }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </div>
            <div className='field-box'>
              <div className='label'>Offset</div>
              <Field
                className='common-dropdown-container offset'
                name='proxy_type'
                component={Select}
                disabled={isReadOnly}
              >
                {proxyTypeOptions.map(({ label, value }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
              <RateProxyOffsetInput
                name='proxy_offset_amt'
                value={values.proxy_offset_amt}
                onChange={handleChange}
                proxyType={values.proxy_type}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
