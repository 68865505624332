import './HelpLayout/HelpStyles.scss';

import { MdClose, MdManageSearch } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { GetSupportArticlesByCategoryDocument } from 'features/help/gql/_gen_/help.gql';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import fuzzysort from 'fuzzysort';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { useQuery } from '@apollo/client';

interface Article {
  id: string;
  title: string;
  category: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

interface GetArticlesData {
  getCategoryArticles: Article[];
}

export const Help = () => {
  const { category, slug } = useParams<{ category: string; slug?: string }>();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');

  const {
    data,
    loading,
    error: getArticlesError,
  } = useQuery<GetArticlesData>(GetSupportArticlesByCategoryDocument, {
    variables: {
      category,
    },
    skip: !category, // Skip the query if selectedCategory is null
  });

  useApolloErrorAlerts([getArticlesError]);

  // Sort the list of articles alphabetically
  const sortedArticles = useMemo(() => {
    return (
      data?.getCategoryArticles
        .slice()
        .sort((a, b) => a.title.localeCompare(b.title)) || []
    );
  }, [data]);

  // Filter the articles based on the search value
  const filteredArticles = useMemo(() => {
    if (!searchValue) return sortedArticles;
    const results = fuzzysort.go(searchValue, sortedArticles, {
      keys: ['title', 'body'],
    });
    return results.map((result) => result.obj);
  }, [searchValue, sortedArticles]);

  const selectedArticle = useMemo(() => {
    return slug === undefined
      ? null
      : sortedArticles.find((article) => article.slug === slug);
  }, [slug, sortedArticles]);

  // Default to the first article in the list if no slug is provided
  useEffect(() => {
    if (!slug && sortedArticles.length > 0) {
      history.push(`/help/${category}/${sortedArticles[0].slug}`);
    }
  }, [sortedArticles, slug, category, history]);

  // Set the active button based on the selected category
  const handleCategoryClick = (newCategory: string) => {
    // If the selected category is already active, do nothing
    if (newCategory === category) return;
    history.push(`/help/${newCategory}`);
  };

  const handleArticleClick = (article: Article) => {
    history.push(`/help/${category}/${article.slug}`);
  };

  const handleEditClick = () => {
    if (selectedArticle) {
      history.push(`/help/edit/${selectedArticle.slug}`);
    }
  };

  return (
    <div className='help-container'>
      <div className='help-header'>
        <div className='btns-container'>
          <button
            className={`articleButton ${
              category === 'general' ? 'active' : ''
            }`}
            onClick={() => handleCategoryClick('general')}
          >
            General
          </button>
        </div>
        <div className='search-container'>
          <div className='search-icon'>
            <MdManageSearch size={20} />
          </div>
          <input
            type='text'
            placeholder='Search articles'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div className='close-icon' onClick={() => setSearchValue('')}>
            <MdClose size={16} />
          </div>
        </div>
      </div>

      <LoadingBar
        className='help-content-container'
        loading={loading}
        overlayStyle={{ borderRadius: '10px' }}
      >
        <>
          <div className='leftColumn'>
            {/* Left side: List of alphabetized article titles */}
            <ul>
              {filteredArticles.map((article) => (
                <li
                  key={article.id}
                  onClick={() => handleArticleClick(article)}
                  className={selectedArticle === article ? 'selected' : ''}
                >
                  {article.title}
                </li>
              ))}
            </ul>
          </div>

          <div className='rightColumn'>
            {selectedArticle && (
              <div>
                <h2>{selectedArticle.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedArticle.body,
                  }}
                />
                <button className='editButton' onClick={handleEditClick}>
                  Edit
                </button>
              </div>
            )}
          </div>
        </>
      </LoadingBar>
    </div>
  );
};
